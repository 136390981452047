import axios from 'axios'
import store from '@/store'
// import Vue from "vue";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000*60*10 // request timeout 10 min.
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    console.log("store.user:",store.getters.session_key)
    if (store.getters.session_key) {
      console.log("add session key to request",store.getters.session_key);
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      
      config.headers['Session-Key'] = store.getters.session_key
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    const res_code=response.status
    // console.log("response:",response)
    console.log('interceptors response',response)
    console.log('res.status',res_code)

    // if the custom code is not 20000, it is judged as an error.
    if (res_code !== 200) {
      console.log("Network error, request code is: ", res_code)
      // Message({
      //   message: res.message || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res_code === 401) {
        console.log("logout, redirect to login");
        // to re-login
        // MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        //   confirmButtonText: 'Re-Login',
        //   cancelButtonText: 'Cancel',
        //   type: 'warning'
        // }).then(() => {
        //   store.dispatch('user/resetToken').then(() => {
        //     location.reload()
        //   })
        // })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      console.log("No http error, return data");
      return res
    }
  },
  error => {

    // const res_code=error.status
    // console.log('interceptors response',response)
    // console.log('res.status',res_code)
    console.log('err: ' ,error.response) // for debug
    if (error.response.status === 401) {
      // clear local data 
      // Vue.$toast.error("Error toast");
      store.commit("logout");
      location.reload();
      // logout 

      // redirect to login 
    }else if (error.response.status === 429){
      console.log("Too many request error");
      document.location.href='/too-many-requests' ;
    }

    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
